import { Component } from 'react';

class ConfirmationRedirect extends Component {
    componentDidMount() {
        window.top.location.href = '/confirmation';
    }
    render() {
        return null;
    }
}

export default ConfirmationRedirect;
